import { useEffect, useMemo, useState } from 'react';
import { Button, Col, List, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import envioMailService from '../../services/http/envioEmailService';
import MyEditor from '../../layouts/myEditor/MyEditor';

const MIN_ENVIO_TO_SHOW = 25;

const BodyRemesa = ({ remesa }) => {
  const [envios, setEnvios] = useState([]);
  const [loadingData, setLoadingData] = useState([]);
  const [errorCargaBody, setErrorCargaBody] = useState(false);
  const [showAllEnvios, setShowAllEnvios] = useState(false);

  const decodeContent = (data) => {
    // Tratamiento como base64.
    try {
      const decoded = atob(data);

      const parser = new DOMParser();
      const doc = parser.parseFromString(decoded, 'text/html');

      if (doc.body.innerHTML.trim()) {
        setErrorCargaBody(false);
        return doc.body.innerHTML;
      }
    } catch (e) {
      // Si falla, es que no es base64, así que continua con HTML normal.
    }

    // Tratamiento como HTML normal
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');

      if (doc.body.innerHTML.trim()) {
        setErrorCargaBody(false);
        return doc.body.innerHTML;
      }
    } catch (e) {
      // Si también falla, no es HTML válido
    }

    // Si todo falla, marcar error y devolver vacío.
    setErrorCargaBody(true);
    return '';
  };

  const contenido = useMemo(() => decodeContent(remesa.html), [remesa.html]);

  const fetchEnvios = async () => {
    setLoadingData(true);

    try {
      const data = await envioMailService.getEnviosByRemesa({
        headquarter: 'CEDESCA',
        idRemesa: remesa.IdRemesa,
      });
      setEnvios(data);
    } catch (error) {
      setEnvios([]);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchEnvios();
  }, []);

  const enviosToShow = useMemo(
    () => (showAllEnvios ? envios : envios.slice(0, MIN_ENVIO_TO_SHOW)),
    [showAllEnvios, envios],
  );

  if (loadingData) {
    return (
      <Col className="mt-3" md="12">
        Cargando envios de la remesa
      </Col>
    );
  }

  return (
    <Row className="mt-4" md="12">
      <Col>
        <h4>Contenido:</h4>
        {errorCargaBody ? (
          <span className="text-danger">No se ha podido cargar el contenido del email.</span>
        ) : (
          <MyEditor
            initialValue={contenido ?? ''}
            initHeight={700}
            content={contenido ?? ''}
            setContent={() => {}}
            disabled
          />
        )}
      </Col>
      <Col>
        <h4>Destinatarios ({envios.length}):</h4>
        <List>
          {enviosToShow.map((e) => (
            <Row className="m-0">
              <li>
                {e.EmailDestino} ({e.Nombre})
              </li>
            </Row>
          ))}
        </List>
        {envios.length >= MIN_ENVIO_TO_SHOW && (
          <Button onClick={() => setShowAllEnvios((prev) => !prev)}>
            {showAllEnvios ? 'Ver menos' : 'Ver más'}
          </Button>
        )}
      </Col>
    </Row>
  );
};

BodyRemesa.propTypes = {
  remesa: PropTypes.any,
};

export default BodyRemesa;
